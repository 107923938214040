import React, { useState } from 'react'
import axios from 'axios'
import './LocationModal.css'

const LocationModal = ({ onLocationSelect }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value)

    if (e.target.value.trim()) {
      // Use OpenStreetMap or any other API for autocomplete
      axios
        .get(
          `https://nominatim.openstreetmap.org/search?q=${e.target.value}&format=json&addressdetails=1`
        )
        .then((response) => {
          setSearchResults(response.data)
        })
        .catch((error) => console.error('Error fetching location data:', error))
    } else {
      setSearchResults([])
    }
  }

  const handleSelect = (location) => {
    const coordinates = [parseFloat(location.lat), parseFloat(location.lon)]
    onLocationSelect(coordinates) // Pass the selected location to App.js
  }

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2>Select Your Location</h2>
        <input
          type="text"
          placeholder="Enter your city"
          value={searchQuery}
          onChange={handleInputChange}
          className="location-input"
        />
        <ul className="search-results">
          {searchResults.map((result) => (
            <li
              key={result.place_id}
              onClick={() => handleSelect(result)}
              className="search-result-item"
            >
              {result.display_name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LocationModal
