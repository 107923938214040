import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginModal from './LoginModal'
import MapComponent from './Map'
import Hero from './Hero'
import Feedback from './Feedback'
import HowItWorks from './HowItWorks'
import Login from './Login'
import LogoutHandler from './LogoutHandler'
import './App.css'
import BlameGame from './BlameGame'
import TogetherForSaferRoads from './TogetherForSaferRoads'
import VisionForFuture from './VisionForFuture'
import Footer from './Footer'
import Header from './Header'
import AccidentReportCoverage from './AccidentReportCoverage'
import LocationModal from './LocationModal'

function App() {
  const [isLoginOpen, setIsLoginOpen] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)

  const handleLocationSelect = (location) => {
    setSelectedLocation(location) // Set the selected location (lat, lng)
  }

  useEffect(() => {
    const token = localStorage.getItem('sesameToken')
    const tokenExpiration = localStorage.getItem('tokenExpiration')
    if (
      token &&
      tokenExpiration &&
      Date.now() < parseInt(tokenExpiration, 10)
    ) {
      setIsLoggedIn(true)
    } else {
      handleLogout()
    }

    const intervalId = setInterval(() => {
      const expiration = localStorage.getItem('tokenExpiration')
      if (expiration && Date.now() >= parseInt(expiration, 10)) {
        handleLogout()
      }
    }, 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  const openLoginModal = () => setIsLoginOpen(true)
  const closeLoginModal = () => setIsLoginOpen(false)

  const handleLogin = () => {
    setIsLoggedIn(true)
    closeLoginModal()
  }

  const handleLogout = () => {
    setIsLoggedIn(false)
    localStorage.removeItem('username')
    localStorage.removeItem('sesameToken')
    localStorage.removeItem('tokenExpiration')
  }

  const username = localStorage.getItem('username')

  return (
    <Router>
      <div className="App">
        <Header
          isLoggedIn={isLoggedIn}
          openLoginModal={openLoginModal}
          handleLogout={handleLogout}
        />

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <BlameGame />
                <AccidentReportCoverage />
                <TogetherForSaferRoads />
                <HowItWorks />
                <Feedback isLoggedIn={isLoggedIn} username={username} />
                {selectedLocation ? (
                  <MapComponent
                    isLoggedIn={isLoggedIn}
                    username={username}
                    initialLocation={selectedLocation} // Pass selected location to map
                  />
                ) : (
                  <LocationModal onLocationSelect={handleLocationSelect} />
                )}
                <VisionForFuture />
                <Footer />
              </>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/logout"
            element={<LogoutHandler onLogout={handleLogout} />}
          />
        </Routes>

        <LoginModal
          isOpen={isLoginOpen}
          onClose={closeLoginModal}
          onLogin={handleLogin}
        />
      </div>
    </Router>
  )
}

export default App
