// BlameGame.js
import React from 'react'
import blameIllustration from './assets/blame-game-illustration.svg' // Update the path as necessary

const BlameGame = () => (
  <section id="blame-game" style={styles.container}>
    <div style={styles.textContainer}>
      <h2 style={styles.title}>The Blame Game!</h2>
      <p style={styles.paragraph}>
        The standard police accident report is mainly designed to{' '}
        <strong>pinpoint blame</strong> and{' '}
        <strong>assign financial responsibility</strong>.
      </p>
      <p style={styles.paragraph}>
        But often, it’s <strong>design choices</strong> that create environments{' '}
        <strong>misaligned</strong> with real <strong>human behavior</strong>.
      </p>
    </div>
    <img
      src={blameIllustration}
      alt="Illustration showing the concept of blame in accidents"
      style={styles.image}
    />
  </section>
)

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '60px 40px',
    backgroundColor: '#002855', // Dark blue background color
    color: '#ffffff', // White text color
  },
  textContainer: {
    maxWidth: '600px',
  },
  title: {
    fontSize: '36px',
    fontWeight: '700',
    marginBottom: '20px',
    color: '#ffffff',
    fontFamily: "'Merriweather', serif",
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '1.6',
    marginBottom: '10px',
    fontFamily: "'Merriweather', serif",
    textAlign: 'left', // Align text to the left
    marginLeft: '50px',
  },
  image: {
    width: '300px',
    height: 'auto',
  },
}

export default BlameGame
