import React from 'react'
import './Hero.css'

// Dynamically import all images from the assets/images directory
const importAll = (r) => r.keys().map(r)
const images = importAll(
  require.context('./assets/images', false, /\.(png|jpe?g|svg)$/)
)

const Hero = () => {
  return (
    <div className="hero-section">
      {images.map((src, index) => {
        const rotation = Math.random() * 20 - 10 // Random rotation between -10 and 10 degrees
        const top = Math.random() * 80 // Random top position between 0% and 80%
        const left = Math.random() * 80 // Random left position between 0% and 80%
        return (
          <img
            key={index}
            src={src}
            alt={`Scattered ${index}`}
            className="scattered-image"
            style={{
              transform: `rotate(${rotation}deg)`,
              top: `${top}%`,
              left: `${left}%`,
            }}
          />
        )
      })}
    </div>
  )
}

export default Hero

// import React from 'react'
// import { Link } from 'react-router-dom' // Import Link for navigation
// import './Hero.css'

// const Hero = () => {
//   return (
//     <div className="hero-container">
//       <div className="hero-content">
//         <h1>
//           Created by a transportation engineer to help identify problem areas
//           and make streets safer for everyone, especially children.
//         </h1>
//         <p>
//           Help make streets safer for everyone. Add markers, share notes, and
//           report issues.
//         </p>
//         <Link to="/learn-more">
//           <button className="hero-button">Learn More</button>
//         </Link>
//       </div>
//     </div>
//   )
// }

// export default Hero
