// LogoutHandler.js
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const LogoutHandler = ({ onLogout }) => {
  const navigate = useNavigate()

  useEffect(() => {
    // Perform the logout logic
    onLogout()
    navigate('/login') // Navigate to the login page
  }, [onLogout, navigate])

  return null // This component doesn't render anything
}

export default LogoutHandler
