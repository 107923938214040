import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import logo from './assets/logo.png'
import './Header.css'

const Header = ({ isLoggedIn, openLoginModal, handleLogout }) => {
  return (
    <header className="header">
      <div className="logo-container">
        <ScrollLink to="home" smooth={true} duration={500}>
          <img src={logo} alt="Route Watcher Logo" className="logo" />
        </ScrollLink>
      </div>
      <nav className="nav">
        <ScrollLink
          to="accidents-dont-cover"
          smooth={true}
          duration={500}
          className="nav-link"
        >
          What Accident Reports Don’t Cover
        </ScrollLink>
        <ScrollLink
          to="together-safer-roads"
          smooth={true}
          duration={500}
          className="nav-link"
        >
          Together for Safer Roads
        </ScrollLink>
        <ScrollLink
          to="how-it-works"
          smooth={true}
          duration={500}
          className="nav-link"
        >
          How it Works
        </ScrollLink>
        <ScrollLink
          to="vision"
          smooth={true}
          duration={500}
          className="nav-link"
        >
          Vision
        </ScrollLink>
        <ScrollLink
          to="contact"
          smooth={true}
          duration={500}
          className="nav-link"
        >
          Contact
        </ScrollLink>
      </nav>
      <div className="auth-button-container">
        {isLoggedIn ? (
          <button onClick={handleLogout} className="login-button">
            Logout
          </button>
        ) : (
          <button onClick={openLoginModal} className="login-button">
            Login
          </button>
        )}
      </div>
    </header>
  )
}

export default Header
