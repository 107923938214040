// TogetherForSaferRoads.js
import React from 'react'
import scooterIllustration from './assets/scooter-illustration.svg' // Update path as necessary

const TogetherForSaferRoads = () => (
  <section id="together-safer-roads" style={styles.container}>
    <div style={styles.textContainer}>
      <h2 style={styles.title}>Together for Safer Roads</h2>
      <p style={styles.paragraph}>
        We believe that everyone has a role to play in making our roads safer.
        Through community reporting, you can contribute by marking locations
        that need attention and sharing potential risks.
      </p>
    </div>
    <div style={styles.imageContainer}>
      <img
        src={scooterIllustration}
        alt="Illustration of person on scooter"
        style={styles.image}
      />
    </div>
  </section>
)

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '60px 40px',
    backgroundColor: '#002855',
    color: '#ffffff',
  },
  textContainer: {
    flex: 1,
    paddingRight: '40px',
  },
  title: {
    fontSize: '36px',
    fontWeight: '700',
    marginBottom: '20px',
    color: '#ffffff',

    fontFamily: "'Merriweather', serif",
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '1.6',
    fontFamily: "'Merriweather', serif",
    textAlign: 'left',
    marginLeft: '50px',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f4a261', // Peach background color
    padding: '60px 0',
    borderTopLeftRadius: '40px',
    borderBottomLeftRadius: '40px',
  },
  image: {
    width: '300px',
    height: 'auto',
  },
}

export default TogetherForSaferRoads
