// VisionForFuture.js
import React from 'react'
import futureVisionImage from './assets/future-vision-image.svg' // Update path if necessary

const VisionForFuture = () => {
  return (
    <section id="vision" style={styles.container}>
      <div style={styles.textContainer}>
        <h2 style={styles.heading}>A Vision for a Safer Future</h2>
        <p style={styles.paragraph}>
          Our mission is to make every street safer. By identifying issues and
          working together, we can ensure that our roads are secure for all,
          including our children and future generations.
        </p>
      </div>
      <div style={styles.imageContainer}>
        <img
          src={futureVisionImage}
          alt="Vision for the Future"
          style={styles.image}
        />
      </div>
    </section>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '60px 40px',
    backgroundColor: '#f4f4f9', // Light background color
    color: '#002855', // Dark text color
  },
  textContainer: {
    flex: 1,
    paddingRight: '40px',
    textAlign: 'left',
  },
  heading: {
    fontSize: '32px',
    fontWeight: '700',
    marginBottom: '20px',
    fontFamily: "'Merriweather', serif",
    textAlign: 'center',
    color: '#002855',
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '1.6',
    fontFamily: "'Merriweather', serif",
    maxWidth: '600px',
    marginLeft: '50px',
    color: '#002855',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '300px',
    height: 'auto',
  },
}

export default VisionForFuture
