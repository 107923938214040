// HowItWorks.js
import React from 'react'
import howItWorksIllustration from './assets/how-it-works-illustration.svg' // Update the path as necessary

const HowItWorks = () => (
  <section id="how-it-works" style={styles.container}>
    <h2 style={styles.heading}>How it Works</h2>
    <div style={styles.contentContainer}>
      <div style={styles.imageContainer}>
        <img
          src={howItWorksIllustration}
          alt="Illustration of how it works"
          style={styles.image}
        />
      </div>
      <div style={styles.stepsContainer}>
        <div style={styles.step}>
          <h3 style={styles.stepTitle}>Log In</h3>
          <p style={styles.stepText}>
            Log in to add markers and engage with the community.
          </p>
        </div>
        <div style={styles.step}>
          <h3 style={styles.stepTitle}>Add a Marker</h3>
          <p style={styles.stepText}>
            Click on the map to highlight a safety issue, add details, and
            upload images.
          </p>
        </div>
        <div style={styles.step}>
          <h3 style={styles.stepTitle}>Like & Engage</h3>
          <p style={styles.stepText}>
            Like markers to increase visibility. More likes = bigger markers!
          </p>
        </div>
        <div style={styles.step}>
          <h3 style={styles.stepTitle}>Explore & Search</h3>
          <p style={styles.stepText}>
            Search for locations or browse the map to find safety issues near
            you.
          </p>
        </div>
      </div>
    </div>
  </section>
)

const styles = {
  container: {
    padding: '60px 40px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '32px',
    fontWeight: '700',
    marginBottom: '40px',
    fontFamily: "'Merriweather', serif",
    color: '#002855',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '250px',
    height: 'auto',
  },
  stepsContainer: {
    flex: 1,
    paddingLeft: '40px',
    textAlign: 'left',
  },
  step: {
    marginBottom: '20px',
    color: '#002855',
  },
  stepTitle: {
    fontSize: '18px',
    fontWeight: '700',
    marginBottom: '10px',
    fontFamily: "'Merriweather', serif",
  },
  stepText: {
    fontSize: '16px',
    lineHeight: '1.6',
    fontFamily: "'Merriweather', serif",
  },
}

export default HowItWorks
