// Footer.js
import React from 'react'
// Import the image from src/assets
import footerLogo from './assets/footer.svg'

const Footer = () => (
  <footer id="contact" style={styles.container}>
    <div style={styles.logoContainer}>
      {/* Use the imported footerLogo as the src */}
      <img src={footerLogo} alt="contact" style={styles.logo} />
      {/* <span style={styles.logoText}>Studio Agatho</span> */}
    </div>
    <div style={styles.content}>
      <h2 style={styles.title}>Safer Together...</h2>
      <address style={styles.address}>
        <p>We’re all ears for your feedback—let us know what you think!</p>
        {/* <p>Social: @reallygreatsite</p> */}
      </address>
    </div>
  </footer>
)

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '40px 60px',
    backgroundColor: '#002855',
    color: '#ffffff',
  },
  content: {
    maxWidth: '600px',
    marginRight: '200px',
  },
  title: {
    fontSize: '28px',
    fontWeight: '700',
    color: '#ffffff',
    marginBottom: '20px',
    fontFamily: "'Merriweather', serif",
  },
  address: {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#ffffff',
    fontFamily: "'Merriweather', serif",
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '300px',
    height: '300px',
    marginLeft: '50px',
  },
  logoText: {
    fontSize: '16px',
    fontWeight: '700',
    color: '#ffffff',
    fontFamily: "'Merriweather', serif",
  },
}

export default Footer
