// AccidentReportCoverage.js
import React from 'react'

const AccidentReportCoverage = () => (
  <section id="accidents-dont-cover" style={styles.container}>
    <h2 style={styles.heading}>What Accident Reports Don’t Cover</h2>
    <div style={styles.cardsContainer}>
      <div style={styles.card}>
        <h3 style={styles.cardTitle}>Pedestrian Crossings</h3>
        <p style={styles.cardText}>
          Crosswalks that are poorly visible or placed in high-speed zones lead
          to accidents, often with pedestrians blamed despite inadequate design
          considerations.
        </p>
      </div>
      <div style={styles.card}>
        <h3 style={styles.cardTitle}>Bicycle Lanes</h3>
        <p style={styles.cardText}>
          Bike lanes abruptly ending or placing cyclists in close proximity to
          fast-moving vehicles, leading to dangerous situations where cyclists
          often get the blame.
        </p>
      </div>
      <div style={styles.card}>
        <h3 style={styles.cardTitle}>Road Curvature and Speed Limits</h3>
        <p style={styles.cardText}>
          Roads designed for speeds higher than the posted limit, encouraging
          drivers to exceed safe speeds unintentionally.
        </p>
      </div>
    </div>
  </section>
)

const styles = {
  container: {
    padding: '60px 40px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '32px',
    fontWeight: '700',
    marginBottom: '40px',
    fontFamily: "'Merriweather', serif",
    color: '#002855',
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  card: {
    backgroundColor: '#f4a261',
    padding: '20px',
    width: '250px',
    borderRadius: '8px',
    textAlign: 'left',
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: '700',
    marginBottom: '10px',
    fontFamily: "'Merriweather', serif",
    color: '#002855',
  },
  cardText: {
    fontSize: '16px',
    lineHeight: '1.6',
    fontFamily: "'Merriweather', serif",
  },
}

export default AccidentReportCoverage
