import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const sesameToken = urlParams.get('sesame')

    if (sesameToken) {
      // Calculate expiration time (e.g., 10 minutes from now)
      const expirationTime = Date.now() + 600 * 1000 // 600 seconds = 10 minutes

      // Store the sesame token and expiration time in localStorage
      localStorage.setItem('sesameToken', sesameToken)
      localStorage.setItem('tokenExpiration', expirationTime)

      navigate('/') // Redirect to the home page
    } else {
      console.error('No sesame token found in URL.')
      navigate('/') // Redirect if no token is found
    }
  }, [location, navigate])

  return (
    <div>
      <p>Logging you in...</p>
    </div>
  )
}

export default Login
