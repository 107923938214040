import React, { useState, useEffect } from 'react'
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  Popup,
  useMapEvents,
  useMap,
} from 'react-leaflet'
import axios from 'axios'
import 'leaflet/dist/leaflet.css'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import 'leaflet-geosearch/dist/geosearch.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './MapComponent.css'

// Define North America bounds
const northAmericaBounds = [
  [5.0, -168.0], // Southwest corner
  [75.0, -52.0], // Northeast corner
]

// Search control component
const SearchControl = () => {
  const map = useMap()

  useEffect(() => {
    const provider = new OpenStreetMapProvider()
    const searchControl = new GeoSearchControl({
      provider,
      style: 'bar',
      showMarker: false,
    })

    map.addControl(searchControl)

    return () => {
      map.removeControl(searchControl)
    }
  }, [map])

  return null
}

const MapComponent = ({ isLoggedIn, initialLocation }) => {
  const [markers, setMarkers] = useState([])
  const [tempMarker, setTempMarker] = useState(null)
  const [mapCenter, setMapCenter] = useState(initialLocation)
  const [zoomLevel, setZoomLevel] = useState(18)
  const minZoomForPosting = 18
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem('username')
  )

  useEffect(() => {
    fetchMarkers()
    setMapCenter(initialLocation) // Update map center when initialLocation changes
  }, [initialLocation])

  const fetchMarkers = () => {
    axios
      .get('https://routewatcher.com/api/markers/')
      .then((response) => setMarkers(response.data))
      .catch((error) => console.error('Error fetching markers:', error))
  }

  const MapClickHandler = () => {
    useMapEvents({
      zoomend: () => {
        setZoomLevel((prev) => prev)
      },
      click(e) {
        if (isLoggedIn) {
          if (zoomLevel >= minZoomForPosting) {
            setTempMarker(e.latlng)
          } else {
            toast.error(
              `Zoom in to at least level ${minZoomForPosting} to post a marker.`
            )
          }
        } else {
          toast.error('Please log in to add a marker.')
        }
      },
    })
    return null
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const category = e.target.category.value
    const note = e.target.note.value
    const image = e.target.image.files[0]
    const latitude = parseFloat(tempMarker.lat.toFixed(6))
    const longitude = parseFloat(tempMarker.lng.toFixed(6))

    if (!category || !latitude || !longitude) {
      toast.error('Category, Latitude, and Longitude are required fields.')
      return
    }

    const formData = new FormData()
    formData.append('category', category)
    formData.append('note', note)
    formData.append('latitude', latitude)
    formData.append('longitude', longitude)

    const username = currentUser
    formData.append('username', username)

    if (image) {
      formData.append('image', image)
    }

    axios
      .post('https://routewatcher.com/api/markers/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 201) {
          setMarkers([...markers, response.data])
          setTempMarker(null)
          toast.success('Marker added successfully!')
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          toast.error(error.response.data.detail || 'Marker limit reached.')
        } else {
          toast.error('An error occurred. Please try again.')
        }
      })
  }

  const handleDelete = (id) => {
    axios
      .delete(`https://routewatcher.com/api/markers/${id}/`)
      .then(() => {
        setMarkers(markers.filter((marker) => marker.id !== id))
        toast.success('Marker deleted successfully!')
      })
      .catch(() => toast.error('Error deleting marker.'))
  }

  const getCategoryColor = (category) => {
    switch (category) {
      case 'pedestrian':
        return '#FF6347' // Tomato red for pedestrians
      case 'cyclist':
        return '#FFD700' // Gold for cyclists
      case 'car':
        return '#1E90FF' // Dodger blue for cars
      default:
        return 'gray'
    }
  }

  const handleLike = (id) => {
    if (!isLoggedIn) {
      toast.error('Please log in to like a marker.')
      return
    }

    axios
      .patch(`https://routewatcher.com/api/markers/${id}/like/`)
      .then(() => {
        setMarkers(
          markers.map((marker) =>
            marker.id === id ? { ...marker, likes: marker.likes + 1 } : marker
          )
        )
      })
      .catch(() => console.error('Error liking marker.'))
  }

  return (
    <div>
      <MapContainer
        center={mapCenter}
        zoom={zoomLevel}
        style={{ height: '500px', width: '100%' }}
        className="map"
        maxBounds={northAmericaBounds}
        maxBoundsViscosity={1.0}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          className="grey-scaled"
        />

        <SearchControl />
        <MapClickHandler />

        {markers.map((marker) => (
          <CircleMarker
            key={marker.id}
            center={[marker.latitude, marker.longitude]}
            radius={10 + marker.likes * 3}
            color={
              marker.username === currentUser
                ? 'green'
                : getCategoryColor(marker.category)
            }
            fillColor={
              marker.username === currentUser
                ? 'green'
                : getCategoryColor(marker.category)
            }
            fillOpacity={0.6}
          >
            <Popup>
              <p>
                <strong>Category:</strong> {marker.category}
              </p>
              <p>
                <strong>Note:</strong> {marker.note}
              </p>
              {marker.image && (
                <div>
                  <p>
                    <strong>Image:</strong>
                  </p>
                  <img
                    src={`https://routewatcher.com${marker.image}`}
                    alt="Uploaded"
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
              )}
              <button onClick={() => handleLike(marker.id)}>
                Like ({marker.likes})
              </button>
            </Popup>
          </CircleMarker>
        ))}

        {tempMarker && (
          <CircleMarker
            center={tempMarker}
            radius={10}
            color="green"
            fillColor="green"
            fillOpacity={0.5}
          >
            <Popup>
              <form onSubmit={handleSubmit}>
                <label>Category:</label>
                <select name="category">
                  <option value="pedestrian">Pedestrian</option>
                  <option value="cyclist">Cyclist</option>
                  <option value="car">Car</option>
                </select>
                <label>Note:</label>
                <input type="text" name="note" placeholder="Add a short note" />
                <label>Image:</label>
                <input type="file" name="image" />
                <button type="submit">Save Marker</button>
              </form>
            </Popup>
          </CircleMarker>
        )}
      </MapContainer>

      <div className="legend">
        <div className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: '#FF6347' }}
          ></span>
          <span>Pedestrian</span>
        </div>
        <div className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: '#FFD700' }}
          ></span>
          <span>Cyclist</span>
        </div>
        <div className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: '#1E90FF' }}
          ></span>
          <span>Car</span>
        </div>
      </div>

      <ToastContainer />
    </div>
  )
}

export default MapComponent
// import React, { useState, useEffect } from 'react'
// import {
//   MapContainer,
//   TileLayer,
//   CircleMarker,
//   Popup,
//   useMapEvents,
//   useMap,
// } from 'react-leaflet'
// import axios from 'axios'
// import 'leaflet/dist/leaflet.css'
// import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
// import 'leaflet-geosearch/dist/geosearch.css'
// import { toast, ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import './MapComponent.css'

// // Define North America bounds
// const northAmericaBounds = [
//   [5.0, -168.0], // Southwest corner
//   [75.0, -52.0], // Northeast corner
// ]

// // Search control component
// const SearchControl = () => {
//   const map = useMap()

//   useEffect(() => {
//     const provider = new OpenStreetMapProvider()
//     const searchControl = new GeoSearchControl({
//       provider,
//       style: 'bar',
//       showMarker: false,
//     })

//     map.addControl(searchControl)

//     return () => {
//       map.removeControl(searchControl)
//     }
//   }, [map])

//   return null
// }

// const MapComponent = ({ isLoggedIn }) => {
//   const [markers, setMarkers] = useState([])
//   const [tempMarker, setTempMarker] = useState(null)
//   const [userLocation, setUserLocation] = useState([43.65107, -79.347015]) // Default: Toronto
//   const [currentUser, setCurrentUser] = useState(
//     localStorage.getItem('username')
//   )
//   const [zoomLevel, setZoomLevel] = useState(18)
//   const minZoomForPosting = 18

//   useEffect(() => {
//     fetchMarkers()

//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setUserLocation([position.coords.latitude, position.coords.longitude])
//         },
//         (error) => {
//           switch (error.code) {
//             case error.PERMISSION_DENIED:
//               toast.error('Permission denied. Please allow location access.')
//               break
//             case error.POSITION_UNAVAILABLE:
//               toast.error('Location unavailable.')
//               break
//             case error.TIMEOUT:
//               toast.error('Request timed out. Please try again.')
//               break
//             default:
//               toast.error('Unknown error occurred.')
//               break
//           }
//           console.error('Error fetching user location:', error)
//         }
//       )
//     } else {
//       toast.error('Geolocation is not supported by your browser.')
//     }
//   }, [])

//   const fetchMarkers = () => {
//     axios
//       .get('https://routewatcher.com/api/markers/')
//       .then((response) => setMarkers(response.data))
//       .catch((error) => console.error('Error fetching markers:', error))
//   }

//   const MapClickHandler = () => {
//     const map = useMapEvents({
//       zoomend: () => {
//         setZoomLevel(map.getZoom())
//       },
//       click(e) {
//         if (isLoggedIn) {
//           if (map.getZoom() >= minZoomForPosting) {
//             setTempMarker(e.latlng)
//           } else {
//             toast.error(
//               `Zoom in to at least level ${minZoomForPosting} to post a marker.`
//             )
//           }
//         } else {
//           toast.error('Please log in to add a marker.')
//         }
//       },
//     })
//     return null
//   }

//   const handleSubmit = (e) => {
//     e.preventDefault()

//     const category = e.target.category.value
//     const note = e.target.note.value
//     const image = e.target.image.files[0]
//     const latitude = parseFloat(tempMarker.lat.toFixed(6))
//     const longitude = parseFloat(tempMarker.lng.toFixed(6))

//     if (!category || !latitude || !longitude) {
//       toast.error('Category, Latitude, and Longitude are required fields.')
//       return
//     }

//     const formData = new FormData()
//     formData.append('category', category)
//     formData.append('note', note)
//     formData.append('latitude', latitude)
//     formData.append('longitude', longitude)

//     const username = currentUser
//     formData.append('username', username)

//     if (image) {
//       formData.append('image', image)
//     }

//     axios
//       .post('https://routewatcher.com/api/markers/', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       })
//       .then((response) => {
//         if (response.status === 201) {
//           setMarkers([...markers, response.data])
//           setTempMarker(null)
//           toast.success('Marker added successfully!')
//         }
//       })
//       .catch((error) => {
//         if (error.response && error.response.status === 403) {
//           toast.error(error.response.data.detail || 'Marker limit reached.')
//         } else {
//           toast.error('An error occurred. Please try again.')
//         }
//       })
//   }

//   const handleDelete = (id) => {
//     axios
//       .delete(`https://routewatcher.com/api/markers/${id}/`)
//       .then((response) => {
//         setMarkers(markers.filter((marker) => marker.id !== id))
//         toast.success('Marker deleted successfully!')
//       })
//       .catch((error) => {
//         toast.error('Error deleting marker.')
//       })
//   }

//   const getCategoryColor = (category) => {
//     switch (category) {
//       case 'pedestrian':
//         return '#FF6347' // Tomato red for pedestrians
//       case 'cyclist':
//         return '#FFD700' // Gold for cyclists
//       case 'car':
//         return '#1E90FF' // Dodger blue for cars
//       default:
//         return 'gray'
//     }
//   }

//   const handleLike = (id) => {
//     if (!isLoggedIn) {
//       toast.error('Please log in to like a marker.')
//       return
//     }

//     axios
//       .patch(`https://routewatcher.com/api/markers/${id}/like/`)
//       .then((response) => {
//         setMarkers(
//           markers.map((marker) =>
//             marker.id === id ? { ...marker, likes: marker.likes + 1 } : marker
//           )
//         )
//       })
//       .catch((error) => {
//         console.error('Error liking marker:', error)
//       })
//   }

//   return (
//     <div>
//       <MapContainer
//         center={userLocation}
//         zoom={zoomLevel}
//         style={{ height: '500px', width: '100%' }}
//         className="map"
//         maxBounds={northAmericaBounds} // Set maximum bounds for North America
//         maxBoundsViscosity={1.0} // Restrict panning outside the bounds
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//           className="grey-scaled"
//         />

//         <SearchControl />
//         <MapClickHandler />

//         {markers.map((marker) => (
//           <CircleMarker
//             key={marker.id}
//             center={[marker.latitude, marker.longitude]}
//             radius={10 + marker.likes * 3}
//             color={
//               marker.username === currentUser
//                 ? 'green'
//                 : getCategoryColor(marker.category)
//             }
//             fillColor={
//               marker.username === currentUser
//                 ? 'green'
//                 : getCategoryColor(marker.category)
//             }
//             fillOpacity={0.6}
//           >
//             <Popup>
//               <p>
//                 <strong>Category:</strong> {marker.category}
//               </p>
//               <p>
//                 <strong>Note:</strong> {marker.note}
//               </p>
//               {marker.image && (
//                 <div>
//                   <p>
//                     <strong>Image:</strong>
//                   </p>
//                   <img
//                     src={`https://routewatcher.com${marker.image}`}
//                     alt="Uploaded"
//                     style={{ width: '100px', height: '100px' }}
//                   />
//                 </div>
//               )}
//               <button onClick={() => handleLike(marker.id)}>
//                 Like ({marker.likes})
//               </button>
//             </Popup>
//           </CircleMarker>
//         ))}

//         {tempMarker && (
//           <CircleMarker
//             center={tempMarker}
//             radius={10}
//             color="green"
//             fillColor="green"
//             fillOpacity={0.5}
//           >
//             <Popup>
//               <form onSubmit={handleSubmit}>
//                 <label>Category:</label>
//                 <select name="category">
//                   <option value="pedestrian">Pedestrian</option>
//                   <option value="cyclist">Cyclist</option>
//                   <option value="car">Car</option>
//                 </select>
//                 <label>Note:</label>
//                 <input type="text" name="note" placeholder="Add a short note" />
//                 <label>Image:</label>
//                 <input type="file" name="image" />
//                 <button type="submit">Save Marker</button>
//               </form>
//             </Popup>
//           </CircleMarker>
//         )}
//       </MapContainer>

//       <div className="legend">
//         <div className="legend-item">
//           <span
//             className="legend-color"
//             style={{ backgroundColor: '#FF6347' }}
//           ></span>
//           <span>Pedestrian</span>
//         </div>
//         <div className="legend-item">
//           <span
//             className="legend-color"
//             style={{ backgroundColor: '#FFD700' }}
//           ></span>
//           <span>Cyclist</span>
//         </div>
//         <div className="legend-item">
//           <span
//             className="legend-color"
//             style={{ backgroundColor: '#1E90FF' }}
//           ></span>
//           <span>Car</span>
//         </div>
//       </div>

//       <ToastContainer />
//     </div>
//   )
// }

// export default MapComponent
