import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Feedback.css'

const Feedback = ({ isLoggedIn, username }) => {
  const [showForm, setShowForm] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [email, setEmail] = useState('') // Pre-fill with username if available
  const [messageSent, setMessageSent] = useState(false)

  const toggleForm = () => {
    if (!isLoggedIn) {
      // Show a toast notification if the user is not logged in
      toast.error('Please log in to access the feedback section.')
      return
    }
    setShowForm(!showForm)
    setMessageSent(false) // Reset message state
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!email || !feedback) {
      alert('Email and feedback are required.')
      return
    }

    const response = await fetch('https://routewatcher.com/feedback/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feedback, email }),
    })

    if (response.ok) {
      setMessageSent(true)
      setFeedback('')
      setEmail('')
    } else {
      alert('Error submitting feedback.')
    }
  }

  return (
    <div className="feedback-container">
      <ToastContainer />
      <button className="feedback-toggle" onClick={toggleForm}>
        {showForm ? 'Close Feedback' : 'Feedback'}
      </button>

      {showForm && (
        <div className="feedback-form-container">
          {/* <button className="close-button" onClick={toggleForm}>
            ×
          </button> */}
          {messageSent ? (
            <p className="feedback-success">Thank you for your feedback!</p>
          ) : (
            <form className="feedback-form" onSubmit={handleSubmit}>
              <label htmlFor="email">Your Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label htmlFor="feedback">Your Feedback:</label>
              <textarea
                id="feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                required
              ></textarea>

              <button type="submit" className="submit-feedback">
                Send
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  )
}

export default Feedback
